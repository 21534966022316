@import '../../helpers/variables';

header {
  width: 100%;
  height: 70px;
  background-image: linear-gradient(to right, #b19043, #8b733b);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 800px) {
    transition: height .4s ease-in;
  }

  nav {
    width: 1280px;
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1400px) {
     width: 1000px;
    }

    @media only screen and (max-width: 1100px) {
      width: 850px;
    }

    @media only screen and (max-width: 900px) {
      width: 750px;
    }

    .logo {
      cursor: pointer;
      height: 60px;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

      @media only screen and (max-width: 800px) {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 800px) {
        flex-direction: column;
        position: absolute;
        left: 50%;
        top: -220px;
        transform: translateX(-50%);
        opacity: 0;
        transition:  opacity .5s .25s;
      }

      li {
        margin: 0 50px;

        @media only screen and (max-width: 1400px) {
          margin: 0 30px;
        }

        @media only screen and (max-width: 1100px) {
          margin: 0 20px;
        }

        @media only screen and (max-width: 800px) {
          margin: 0;
          margin-top: 25px;
        }
      }
    }

    .flags {
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 800px) {
        position: absolute;
        left: 20px;
        top: 25px;
      }

      .flag {
        cursor: pointer;
        transition: all .3s ease;
        width: 30px;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

        &:hover {
          transform: scale(1.1);

          @media only screen and (max-width: 800px) {
            transform: scale(1);
          }
        }

        &:active {
          transform: scale(0.95);

          @media only screen and (max-width: 800px) {
            transform: scale(1);
          }
        }

        &:not(:last-child) {
          margin-right: 20px;

          @media only screen and (max-width: 450px) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.nav-link {
  text-decoration: none;
  color: #2c1302;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  transition: color .3s;

  &:hover {
    color: #612904;

    @media only screen and (max-width: 800px) {
      color: #2c1302;
    }
  }

  &:focus:hover {
    color: #2c1302;
  }

  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 800px) {
    font-size: 24px;
  }

  &::after {
    content: '';
    width: 100%;
    height: 2.5px;
    background-color: #2c1302;
    position: absolute;
    bottom: -5px;
    left: 0;
    transform: scaleX(0);
    opacity: 0;
    transition: all .3s ease-out;
    border-radius: 5px;
  }
}

.nav-link-active {
  
  &::after {
    content: '';
    width: 100%;
    height: 2.5px;
    background-color: #2c1302;
    position: absolute;
    bottom: -5px;
    left: 0;
    transform: scaleX(1);
    opacity: 1;
    transition: all .3s ease-out;
    border-radius: 5px;
  }
}

.menu-btn {
  display: none;

  @media only screen and (max-width: 800px) {
    display: block; 
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

.menu-active {
  top: 70px;
  opacity: 1;
}

.header-height {
  height: 310px;
}