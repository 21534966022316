.pricing {
  color: #fff;
  text-align: center;
  width: fit-content;
  height: fit-content;
  background-image: linear-gradient(
    to right bottom,
    rgba(163, 129, 51, .8),
    rgba(78, 39, 12, .8)
  );
  box-shadow: #2c1302 0px 20px 30px -10px;
  padding: 75px;
  border-radius: 20px;
  margin: 75px;
  
  @media only screen and(max-width: 1000px) {
    margin: 0;
    border-radius: 0;
    padding: 75px 50px;
    box-shadow: none;
    background-image: linear-gradient(
      to right bottom,
      rgba(163, 129, 51, 1),
      rgba(78, 39, 12, 1)
    );
  }

  h2 {
    font-size: 24px;
    border-bottom: 2px solid #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: inline-block;

    &:not(:first-child) {
      margin-top: 50px;
    }

    @media only screen and(max-width: 800px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 22px;
    letter-spacing: 0.5px;

    @media only screen and(max-width: 800px) {
      font-size: 18px;
    }
  }
}

.pricing-p-bottom-margin {
  margin-bottom: 10px;
}
