.projects {
  color: white;
  display: grid;
  grid-template-columns: repeat(3, 350px);
  grid-gap: 50px;
  margin: 75px;
  justify-content: center;

  @media only screen and(max-width: 1280px) {
    grid-template-columns: repeat(2, 350px);
    grid-column-gap: 150px;
  }

  @media only screen and(max-width: 1000px) {
    grid-column-gap: 75px;
  }

  @media only screen and(max-width: 900px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-column-gap: 50px;
  }

  @media only screen and(max-width: 800px) {
    grid-template-columns: 350px;
    grid-column-gap: 0;
    grid-row-gap: 80px;
  }

  @media only screen and(max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
