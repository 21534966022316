.biography {
  max-width: 1000px;
  background-image: linear-gradient(
    to right bottom,
    rgba(163, 129, 51, 0.8),
    rgba(78, 39, 12, 0.8)
  );
  box-shadow: #2c1302 0px 20px 30px -10px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  margin: 75px;
  padding: 50px 0;

  @media only screen and(max-width: 1000px) {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    margin: 0;
    background-image: linear-gradient(
      to right bottom,
      rgba(163, 129, 51, 1),
      rgba(78, 39, 12, 1)
    );
  }

  @media only screen and(max-width: 800px) {
    flex-direction: column;
  }

  @media only screen and(max-width: 600px) {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding-top: 50px;
  }

  .biography-left {
    width: 45%;

    @media only screen and(max-width: 1100px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media only screen and(max-width: 800px) {
      width: 100%;
      margin: 0 auto;
    }

    .biography-image {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      margin: 20px auto;

      @media only screen and(max-width: 1100px) {
        width: 250px;
        height: 250px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(1.5) translateX(-20px) translateY(30px);
      }
    }

    .biography-name {
      font-size: 40px;
      letter-spacing: 0.5px;
      text-align: center;

      @media only screen and(max-width: 480px) {
        font-size: 30px;
      }
    }

    .biography-licences {
      @media only screen and(max-width: 800px) {
        max-width: 600px;
        margin: 0 auto;
      }

      .licences-title {
        font-size: 24px;
        margin: 50px 0 30px 95px;
        margin-left: 70px;
        text-align: left;
        letter-spacing: 1px;

        @media only screen and(max-width: 900px) {
          text-align: center;
          margin-left: 0;
        }

        @media only screen and(max-width: 480px) {
          font-size: 22px;
        }
      }

      .licence {
        height: 50px;
        display: flex;
        align-items: center;
        margin-left: 70px;
        margin-bottom: 20px;

        @media only screen and(max-width: 900px) {
          margin-left: 0;
          justify-content: space-between;
          margin-left: 50px;
          margin-right: 50px;
          text-align: right;
        }

        .licence-image {
          min-width: 40px;
          max-width: 40px;
          height: 50px;
          overflow: hidden;
          border-radius: 10px;
          margin-right: 30px;
          cursor: pointer;

          &:hover {
            animation: licenceImageAnimation 1.3s infinite linear;

            @media only screen and(max-width: 500px) {
              animation: none;
            }
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .licence-name {
          letter-spacing: 0.5px;
          font-size: 18px;

          @media only screen and(max-width: 480px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .biography-right {
    width: 55%;
    padding: 0 50px;

    @media only screen and(max-width: 900px) {
      width: 50%;
      padding-left: 50px;
      padding-right: 0px;
    }

    @media only screen and(max-width: 800px) {
      width: 100%;
      margin-right: 0 auto;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 20px;
    }

    .biography-text {
      letter-spacing: 1px;
      line-height: 120%;
      font-size: 18px;
      text-align: justify;

      @media only screen and(max-width: 600px) {
        text-align: justify;
      }

      @media only screen and(max-width: 480px) {
        text-align: left;
        font-size: 16px;
      }
    }

    .biography-contact {
      font-size: 18px;
      letter-spacing: 0.5px;
      text-align: center;
      margin-top: 60px;

      @media only screen and(max-width: 480px) {
        font-size: 16px;
      }

      .biography-contact-link {
        text-decoration: none;
        color: rgb(80, 118, 241);

        &:hover {
          text-decoration: underline;
        }
      }

      &-span {
        letter-spacing: 0.5px;
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

@keyframes licenceImageAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.indent {
  text-indent: 50px;
}
