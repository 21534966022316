.background-container {
  width: 100%;
  height: 100%;

}
.overlay {
  opacity: 1;
}

.buildings-images {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right bottom,
    rgba(163, 129, 51, 1),
    rgba(78, 39, 12, 1)
  );
  padding: 70px;
  margin: 0 auto;

  @media only screen and(max-width: 1100px) {
    padding: 70px 50px;
  }

  @media only screen and(max-width: 1000px) {
    padding: 70px 40px;
  }

  @media only screen and(max-width: 800px) {
    padding: 70px 30px;
  }

  &-img {
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
    min-height: 700px;
    border-radius: 5px;
    overflow: hidden;

    @media only screen and(max-width: 800px) {
      min-height: auto;
    }

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
