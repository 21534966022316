@import '../../helpers/variables';

footer {
  width: 100%;
  padding: 1.5rem 2rem;
  background-image: linear-gradient(to right, #2c1302, #4e2204);
  color: rgb(184, 179, 179);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 1000px) {
    justify-content: space-evenly;
  }

  #hoqmee-partner-render > img {
    object-fit: contain;
  }

  #hoqmee-partner-render {
    position: absolute;
    right: 10%;
    height: 100px;

    @media only screen and (max-width: 1500px) {
      right: 5%;
    }

    @media only screen and (max-width: 1200px) {
      right: 0%;
    }

    @media only screen and (max-width: 700px) {
     position: relative;
    }
  }

  .footer-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1000px) {
      margin-right: 10rem;
    }

    @media only screen and (max-width: 850px) {
      margin-right: 0;
    }

    @media only screen and (max-width: 700px) {
      margin-top: 2rem;
    }
  }

  .copyright {
    font-size: 18px;
    margin-bottom: 1rem;
    letter-spacing: 0.5px;
    line-height: 1.5rem;
    pointer-events: none;
    display: flex;

    @media only screen and (max-width: 850px) {
      flex-direction: column;
      align-items: center;
    }

    p {
      &:not(:last-child) {
        margin-right: 0.5rem;

        @media only screen and (max-width: 850px) {
          margin-right: 0;
        }
      }
    }
  }

  .designer {
    letter-spacing: 0.5px;
    font-size: 14px;
    pointer-events: none;
  }
}

@media only screen and (max-width: 600px) {
  .partners-modal {
    height: 70%;
    overflow-y: scroll;
  }
}

/*
    font-size: 18px;
    margin: 1rem 0;
    letter-spacing: 0.5px;
    line-height: 1.5rem;
    pointer-events: none;

    letter-spacing: 0.5px;
    font-size: 14px;
    pointer-events: none;
*/
