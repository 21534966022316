.contact {
  color: #fff;
  background-image: linear-gradient(
    to right bottom,
    rgba(163, 129, 51, .8),
    rgba(78, 39, 12, .8)
  );
  box-shadow: #2c1302 0px 20px 30px -10px;
  padding: 75px;
  border-radius: 20px;
  margin: 75px;
  display: flex;
  align-items: center;
  width: 1100px;
  height: 800px;

  @media only screen and(max-width: 1200px) {
    width: 1000px;
  }

  @media only screen and(max-width: 1100px) {
    width: 900px;
  }


  @media only screen and(max-width: 1000px) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    padding: 75px 50px;
    box-shadow: none;
    flex-direction: column;
    height: fit-content;
    background-image: linear-gradient(
      to right bottom,
      rgba(163, 129, 51, 1),
      rgba(78, 39, 12, 1)
    );
  }

  .contact-text {
    width: 50%;
    display: flex;
    flex-direction: column;

    @media only screen and(max-width: 1000px) {
      align-items: center;
      width: 100%;
    }

    .contact-header {
      margin-bottom: 70px;
      font-size: 30px;
      letter-spacing: 0.5px;

      @media only screen and(max-width: 1000px) {
        font-size: 24px;
      }

      @media only screen and(max-width: 1000px) {
        text-align: center;
        margin-bottom: 50px;
      }
    }

    .contact-info {
      display: flex;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 20px;
      letter-spacing: 0.5px;

      @media only screen and(max-width: 1000px) {
        font-size: 18px;
      }
    }

    a {
      font-size: 20px;
      letter-spacing: 0.5px;
      transition: color 0.2s;

      @media only screen and(max-width: 1000px) {
        font-size: 18px;
      }

      @media only screen and(max-width: 1000px) {
        transition: none;
      }

      &:hover {
        color: rgb(48, 48, 247);

        @media only screen and(max-width: 1000px) {
          color: #fff;
        }
      }
    }
  }
}

.contact-icon {
  margin-right: 30px;
}

.contact-instagram-icon {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  border-radius: 10px;
}

.contact-youtube-icon {
  fill: #ff0000;
  background-image: radial-gradient(white 10%, transparent 50%);
}

.contact-icon-pib {
  margin-right: 1.6rem;
  width: 2rem;
}

.contact-images {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and(max-width: 800px) {
    align-items: center;
  }

  .contact-img {
    width: 370px;
    height: 270px;
    overflow: hidden;
    border-radius: 10px;

    @media only screen and(max-width: 1100px) {
      width: 300px;
    }

    @media only screen and(max-width: 1000px) {
      width: 370px;
    }

    @media only screen and(max-width: 500px) {
      width: 300px;
      height: 250px;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.contact-phone,
.contact-mobile {
  pointer-events: none;

  @media only screen and(max-width: 600px) {
    pointer-events: all;
  }
}

.contact-mid-line {
  height: 100%;
  width: 2px;
  background-color: #eee;
  opacity: 0.5;

  @media only screen and(max-width: 1000px) {
    width: 100%;
    height: 2px;
    margin: 50px 0;
  }
}
