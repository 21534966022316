.licence-modal {
  width: fit-content;
  height: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 5px;
  z-index: 100;
  background-image: linear-gradient(to right bottom, #8b733b, #2c1302);
  box-shadow: #2c1302 0px 20px 30px -10px;

  @media only screen and(max-width: 700px) {
    top: 45%;
  }

  @media only screen and(max-width: 500px) {
    height: 500px;
    top: 38%;
  }

  @media only screen and(max-width: 480px) {
    top: 40%;
  }

  @media only screen and(max-width: 400px) {
    height: 450px;
  }

  @media only screen and(max-width: 370px) {
    height: 400px;
  }

  img {
    display: block;
    margin: 0 auto;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1);
  }

  .close-modal-div {
    width: 130px;
    height: 130px;
    background-image: linear-gradient(to right bottom, #b89a54, #834013);
    position: absolute;
    top: -50px;
    right: -50px;
    border-bottom-left-radius: 50%;

    .close-modal {
      position: absolute;
      top: 60px;
      right: 60px;
      color: black;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }
}

.moval {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.uverenje {
  width: auto;
  height: 400px;

  @media only screen and(max-width: 650px) {
    height: 300px;
  }

  @media only screen and(max-width: 500px) {
    height: 220px;
  }
}