.projects-item {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and(max-width: 800px) {
    flex-direction: column-reverse;

    &:not(:first-child) {
      margin-top: 100px;
    }
  }

  @media only screen and(max-width: 500px) {
    width: 300px;
  }

  @media only screen and(max-width: 380px) {
    width: 250px;
  }

  .projects-img {
    overflow: hidden;
    width: 100%;
    height: 80%;
    cursor: pointer;
    border-radius: 5px;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.65;
    }
  }

  .projects-title {
    margin-top: 30px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 400;
    position: relative;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.5px;

    @media only screen and(max-width: 800px) {
      margin-bottom: 30px;
      margin-top: 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 1.5px;
      background-color: #fff;
      position: absolute;
      bottom: -5px;
      left: 0;
      transform: scaleX(0);
      opacity: 0;
      transition: all 0.3s ease-out;
      border-radius: 5px;
    }

    &:hover::after {
      transform: scaleX(1);
      opacity: 1;

      @media only screen and (max-width: 500px) {
        transform: scaleX(0);
        opacity: 0;
        transition: none;
      }
    }
  }
}
