@import '../../helpers/variables';

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;

  .main-img-logo {
    width: 300px;
    height: 300px;
    margin-right: 600px;
    animation: logoAnimation 3s infinite linear;

    @media only screen and (max-width: 1200px) {
      margin-right: 400px;
    }

    @media only screen and (max-width: 1000px) {
      width: 250px;
      height: 250px;
    }

    @media only screen and (max-width: 900px) {
      margin-right: 300px;
    }

    @media only screen and (max-width: 800px) {
      margin-right: 200px;
    }

    @media only screen and (max-width: 700px) {
      margin-right: 100px;
    }

    @media only screen and (max-width: 600px) {
      margin-right: 0;
      margin-top: 400px;
    }

    @media only screen and (max-width: 500px) {
      width: 200px;
      height: 200px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .main-text {
    margin-top: 50px;
    margin-right: 600px;
    pointer-events: none;

    @media only screen and (max-width: 1200px) {
      margin-right: 400px;
    }

    @media only screen and (max-width: 900px) {
      margin-right: 300px;
    }

    @media only screen and (max-width: 800px) {
      margin-right: 200px;
    }

    @media only screen and (max-width: 800px) {
      margin-top: 50px;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (max-width: 700px) {
      margin-right: 100px;
    }

    @media only screen and (max-width: 600px) {
      margin-right: 0;
      margin-bottom: 70px;
    }

    h1 {
      color: #b19043;
      font-size: 60px;
      letter-spacing: 0.5px;

      @media only screen and (max-width: 1000px) {
        font-size: 50px;
      }

      @media only screen and (max-width: 600px) {
        font-size: 40px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 30px;
      }
    }

    p {
      font-size: 30px;
      color: #b19043;
      margin-top: 10px;
      letter-spacing: 0.5px;

      @media only screen and (max-width: 1000px) {
        font-size: 25px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 20px;
      }
    }
  }
}

@keyframes logoAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0);
  }
}
