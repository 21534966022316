.container {
  width: 100%;
  position: relative;
  background: url('../public/images/bgimg-wide.jpg') no-repeat center center /
    cover;
  overflow: hidden;

  @media only screen and(max-width: 1000px) {
    background: url('../public/images/bgimg-mid.jpg') no-repeat center center /
      cover;
    .background-container {
      background-image: linear-gradient(
        to right bottom,
        rgba(163, 129, 51, 1),
        rgba(78, 39, 12, 1)
      );
    }
  }

  @media only screen and(max-width: 600px) {
    background: url('../public/images/bgimg-phone.jpg') no-repeat center center /
      cover;
  }

  .overlay {
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

*,
::after,
::before {
  -webkit-tap-highlight-color: transparent;
}
